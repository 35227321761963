/*
 *  Class Main
 */

export
default class Main {

    constructor() {}

    start() {
        console.log('in START');
        //$('body').css({'background':'blue'});
       // $('.tabs').tabs();

    //    $('.modal').modal();
          
    }

}